exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-index-js": () => import("./../../../src/pages/Contact/index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-education-index-js": () => import("./../../../src/pages/Education/index.js" /* webpackChunkName: "component---src-pages-education-index-js" */),
  "component---src-pages-experience-index-js": () => import("./../../../src/pages/Experience/index.js" /* webpackChunkName: "component---src-pages-experience-index-js" */),
  "component---src-pages-first-page-first-page-js": () => import("./../../../src/pages/FirstPage/FirstPage.js" /* webpackChunkName: "component---src-pages-first-page-first-page-js" */),
  "component---src-pages-hobbies-index-js": () => import("./../../../src/pages/Hobbies/index.js" /* webpackChunkName: "component---src-pages-hobbies-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-logo-logo-js": () => import("./../../../src/pages/Logo/Logo.js" /* webpackChunkName: "component---src-pages-logo-logo-js" */),
  "component---src-pages-projects-index-js": () => import("./../../../src/pages/Projects/index.js" /* webpackChunkName: "component---src-pages-projects-index-js" */),
  "component---src-pages-resume-index-js": () => import("./../../../src/pages/Resume/index.js" /* webpackChunkName: "component---src-pages-resume-index-js" */),
  "component---src-pages-skills-index-js": () => import("./../../../src/pages/Skills/index.js" /* webpackChunkName: "component---src-pages-skills-index-js" */)
}

